/**
 *      TORUS
 */
import Torus, { UserInfo } from "@toruslabs/solana-embed";
/**
 *      TYPES
 */
import GeneralUserInfo from './types/userInfo'

export const createTorus = () => new Torus()

export const init = async (torus: Torus) => await torus.init({
    // buildEnv: "testing", // uses solana-testing.tor.us (which uses testnet)
    buildEnv: "production",
    enableLogging: true, // default : false
    showTorusButton: true, // default: true,
    apiKey:
        "BOEax-2wubBqjUU6-A-vFribcV7h5jEq0_ofwR5CKsaj7Uv0Us2D2smtT_3G6_-O--5axcnoAXisy752EV7SWgI",
    useLocalStorage: true,
})

export const getUserInfo = async (torus: Torus): Promise<GeneralUserInfo> => {
    const userInfo = await torus.getUserInfo()

    return {
        email: userInfo.email,
        name: userInfo.name,
        profileImage: userInfo.profileImage,
        verifier: userInfo.verifier,
        verifierId: userInfo.verifierId
    }
}