/**
 *      FRAMEWORK
 */
import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
/**
 *      SOLANA
 */
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  useWallet,
  useConnection,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  clusterApiUrl,
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
/**
 *      UTILS
 */
import axios from "axios";
/**
 *      TORUS
 */
 import UserInfo from './torus/types/userInfo'
 import { createTorus, init, getUserInfo } from './torus/solana'
/**
 *      STYLES
 */
require("./App.css");
require("@solana/wallet-adapter-react-ui/styles.css");
const torus = createTorus()

const getTokenParameter = () =>
  new URL(window.location.href).searchParams.get("token");

const App: FC = () =>
  <Context>
    <Content />
  </Context>

export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

const Content: FC = () => {
  const { sendTransaction, wallet } = useWallet();
  const { connection } = useConnection();

  const [isLoggedIn, setIsLoggedIn] = useState(torus.isLoggedIn);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [publicKey, setPublicKey] = useState<string>();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // console.log("Is User logged in?", torus.isLoggedIn);
  });

  const onClickLogin = async () => {
    setIsLoading(true)

    if (!torus.isInitialized) {
      await init(torus);
    }

    try {
      // let torusIframe: HTMLIFrameElement | null = document.getElementById("torusIframe") as HTMLIFrameElement

      // if (torusIframe === null)
      //   throw new Error("torusIframe === null")

      // if (torusIframe.contentWindow === null)
      //   throw new Error("torusIframe.contentWindow === null")

      // const elements = torusIframe
      //   .contentWindow
      //   .document
      //   .getElementsByClassName("embed-google") as HTMLCollectionOf<HTMLButtonElement>

      // if (elements.length !== 1)
      //   throw new Error("elements.length !== 1")

      // const element = elements[0]
      // element.click()

      const publicKeys = await torus.login(); // return array of public key in base 58
      const firstPublicKey = publicKeys[0];
      console.log("publicKey", firstPublicKey);
      setPublicKey(firstPublicKey);

      const userInfo = await getUserInfo(torus);
      setUserInfo(userInfo);
      console.log("userInfo", userInfo);

      setIsLoggedIn(true);

      // const params = {
      //   Authenticated: true,
      //   Token: getTokenParameter(),
      //   // Wallet: publicKey,
      //   // UserInfo: userInfo,
      // };
      //   const paramsGet = new URLSearchParams(...params).toString();

      // await axios.post(
      //   "https://mende-bot-develop-ngdpjbbjuq-uc.a.run.app/api/AuthUserV2",
      //   params
      // );

      axios.defaults.headers.common["X-API-Key"] =
        "gGiHLg9/vKhct+TEUxO6P999hJSEPJcaaO7RoGTkJio=";

      axios
        .get(
          "https://mende-bot-staging-v2-ngdpjbbjuq-uc.a.run.app/api/AuthUser?token=" +
          getTokenParameter()
          + "&authenticated=true"
          + "&walletAddress=" + firstPublicKey
        )
        .then((data) => {
          window.close();
        });
    } catch (error) {
      alert(error);
      setIsLoading(false)
    }
  };

  const onClickLogout = async () => {
    try {
      await torus.logout();

      setIsLoggedIn(false);
    } catch (error) { }
  };

  // const onClickTransfer = async () => {
  //   if (!publicKey) return;

  //   // const latestBlockhash = await connection.getLatestBlockhash("singleGossip");
  //   // const blockhash = latestBlockhash.blockhash;
  //   // const blockHeight = latestBlockhash.lastValidBlockHeight;
  //   // console.log("blockhash", blockhash);
  //   // console.log("blockHeight", blockHeight);

  //   const recentBlockhash = await connection.getRecentBlockhash("recent");
  //   const blockhash = recentBlockhash.blockhash;
  //   console.log("blockhash", blockhash);

  //   // const network = clusterApiUrl("mainnet-beta");
  //   // const connection = new Connection(network);
  //   // const blockhash = (await connection.getLatestBlockhash("finalized"))
  //   //   .blockhash;

  //   const destPublicKey = "5UNTBvCJmfTzy58JtfP3K4QJrpwJe9ysugTkDtiZKSb1";
  //   const transactionInstruction = SystemProgram.transfer({
  //     fromPubkey: new PublicKey(publicKey),
  //     toPubkey: new PublicKey(destPublicKey),
  //     lamports: 0.0001 * LAMPORTS_PER_SOL,
  //   });
  //   const transaction = new Transaction({
  //     recentBlockhash: blockhash,
  //     feePayer: new PublicKey(publicKey),
  //   }).add(transactionInstruction);

  //   const res = await torus.sendTransaction(transaction);
  //   console.log("res", res);
  // };

  const loggedOutFragment = <button onClick={onClickLogin}>LogIn</button>;
  const loggedinFragment = (
    <>
      {/* <button onClick={onClickTransfer}>Transfer</button> */}
      <button onClick={onClickLogout}>LogOut</button>
    </>
  );

  console.log("torus.isLoggedIn", torus.isLoggedIn);

  //   return (
  //     <div className="App">
  //       {isLoggedIn ? loggedinFragment : loggedOutFragment}
  //     </div>
  //   );

  return (
    <div className="container">
      {isLoggedIn && <>Please close the browser.</>}

      {!isLoggedIn && !isLoading && (
        <>
          <div className="logo-container">
            <img className="logo" src="/res/bonuz.png" />
          </div>
          <hr />
          <div className="content">
            <div className="description">
              Choose your preferred way to login.
            </div>
            <button className="primary">Continue with Bonuz</button>

            <div className="socials">
              <img src="/res/google.png" onClick={onClickLogin} />
              <img src="/res/apple.png" />
              <img src="/res/discord.png" />
              <img src="/res/facebook.png" />
              <img src="/res/telegram.png" />
            </div>
          </div>
          <div className="footer">
            <img src="/res/metamask.png" />
            <img src="/res/binance_wallet.png" />
            <img src="/res/phantom.png" />
            <img src="/res/sollet.png" />
          </div>
        </>
      )}
    </div>
  );
};
